<template>
  <div>
    <!--      群聊页面-->
    <div class="pageCode">
      <div class="qc_code">
        <div class="code-box">
          <div class="top">
            <div class="right">
              <div class="shop_name">{{ roomNews.name }}</div>
            </div>
          </div>
          <div class="bottom">
            <div class="code" style="text-align: center;">
              <img :src="roomNews.roomQrcode" alt="" class="qr_room_img"/>
            </div>
          </div>
          <div class="shop_guide">长按识别图中二维码加入群聊</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {openUserInfoApi, posterApi} from '@/api/roomFission'

export default {
  name: "index",
  data() {
    return {
      url: window.location.href,
      dataList: {},
      userInfo: {},
      bgHeight: 'auto',
      base64Img: '',
      //    微信授权信息
      wxUserData: {},
      //  群聊信息
      roomNews: {},
      //  用户海报信息
      posterNews: {},
      canvasImg:'',
      parentUnionId: '',
      fissionId: 0,
      wxUserId: ''
    }
  },

  created() {
    this.canvasImg = ''
    this.fissionId = this.$route.query.id;
    // 需要兼容老的query参数
    if (this.$route.query.parentUnionId !== undefined) {
      this.parentUnionId = this.$route.query.parentUnionId;
    } else if (this.$route.query.parent_union_id !== undefined) {
      this.parentUnionId = this.$route.query.parent_union_id;
    }

    if (this.$route.query.wxUserId !== undefined) {
      this.wxUserId = this.$route.query.wxUserId;
    } else if (this.$route.query.wx_user_id !== undefined) {
      this.wxUserId = this.$route.query.wx_user_id;
    }

    this.getOpenUserInfo();
  },
  methods: {
    getOpenUserInfo() {
      let that = this;
      openUserInfoApi({
        id: that.fissionId
      }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl = '/auth/roomFission?id=' + that.fissionId + '&target=' + encodeURIComponent(that.url);
          that.$redirectAuth(redirectUrl);
        }
        this.wxUserData = res.data;
        this.getPosterData();
      });
    },
    //  获取海报数据
    getPosterData() {
      let params = {
        fissionId: this.fissionId,
        parentUnionId: this.parentUnionId,
        wxUserId: this.wxUserId,
        unionId: this.wxUserData.unionid,
        nickname: this.wxUserData.nickname,
        avatar: this.wxUserData.headimgurl,
        type: 1
      }
      posterApi(params).then((res) => {
        document.title = res.data.fission.activeName ?? "群裂变"
        if (res.data.type == 0) {
          this.$message.info('活动已结束');
          return false
        }
        this.roomNews = res.data.room
      })
    }
  }
}
</script>

<style scoped lang="less">
.page {
  width: 100vw;
  height: 100vh;
  background-color: #ff5636;
  padding: 0 20px 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("../../static/images/bg.png");
  background-size: cover;
  overflow-y: auto;

  .top {
    .reply {
      padding-top: 16px;

      .content {
        padding: 16px 20px;
        border-radius: 16px;
        background-color: rgb(253, 253, 229);

        .title {
          display: flex;
          justify-content: space-between;

          .tip {
            color: saddlebrown;
            font-size: 15px;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 7px;
              background-color: #b97e1229;
            }
          }

          .copy {

          }
        }

        .text {

        }
      }


    }

    .tips {
      padding: 20px 0;

      .tip {
        display: flex;
        justify-content: center;
        margin-bottom: 4px;

        .text {
          position: relative;
          color: white;
          z-index: 0;

          &::before {
            position: absolute;
            content: '';
            bottom: 0;
            z-index: -1;

            left: ((100% - 90%) / 2);

            height: 10px;
            width: 90%;
            border-radius: 100px;
            background-color: rgba(255, 255, 255, 0.15);
          }
        }

        .long-text {
          &::before {
            left: ((100% - 110%) / 2);
            width: 110%;
          }
        }
      }
    }

    .code-image {
      width: 100%;
      background-color: navajowhite;
      border-radius: 10px;
      box-shadow: inset 0 4px 6px 0 #0000001c;
      border: 2px solid white;
      padding: 10px;
      margin-top: 30px;
      .code-border {
        width: 100%;
        padding: 16px;
        background-color: white;
        border-radius: 12px;
      }
    }
  }

  .bottom-box {
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 0 15px;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.19);
    background-color: #ff5636;
    display: flex;
    justify-content: center;

    .long-btn {
      width: 90%;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: darkorange;
      font-size: 16px;
      border: 2px solid #ffd6a1;
      border-radius: 100px;
      background-color: #ffecdb;
    }
  }


}

.show_poster {
  width: 100%;
  height: 75vh;
  position: relative;
  //background: skyblue;
}

.cover_pic {
  width: 100%;
  height: 100%;
}

//.show_user_news {
//  position: absolute;
//  width: 100%;
//  height: 100%;
//  top: 0;
//}

.show_user_data {
  margin-left: 15px;
  margin-top: 15px;
  display: flex;
  position: absolute;
  top: 0;
}

.qr-code {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.user_img {
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

.user_name {
  margin-left: 10px;
  margin-top: 6px;
  font-size: 15px;
  font-weight: bold;
}

.user_qrCode {
  width: 50px;
  width: 50px;
}

//abc
.pageCode {
  width: 100vw;
  height: 100vh;
  background-color: #bfddff;
}

.qr_room_img {
  width: 217px;
  height: 217px;
  //height: auto;
}

.qc_code {
  display: flex;
  justify-content: center;
  .code-box {
    margin-top: 50px;
    width: 90vw;
    height: auto;
    background-color: #ffffff;
    padding-bottom: 20px;
  }

  .top {
    //display: flex;
    //align-items: center;
    padding: 28px 28px 20px 28px;

    .left {
      margin-right: 15px;
    }
  }

  .shop_name {
    font-size: 17px;
    color: #222;
    font-weight: bold;
    text-align: center;
  }

  .shop_describe {
    color: #818181;
  }

  .shop_guide {
    margin-top: 17px;
    text-align: center;
    color: #818181;
  }
}
</style>
